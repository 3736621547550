import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import AuthorPanel from "./authorPanel"

export default function BlogPostSummary({
  post: {
    name,
    childMarkdownRemark: { frontmatter },
  },
}) {
  return (
    <article className="post-card">
      <Link to={`/blog/${name}/`}>
        <div className="post-card-img">
          <Img
            className="img-responsive"
            fluid={frontmatter.image.childImageSharp.fluid}
            alt={frontmatter.title}
          />
        </div>
        <span className="tag">{frontmatter.category}</span>
        <h4 className="blog-card-title">{frontmatter.title}</h4>
        <p className="excerpt-card">{frontmatter.summary}</p>
        <AuthorPanel author={frontmatter.author} date={frontmatter.date} />
      </Link>
    </article>
  )
}

export const query = graphql`
  fragment BlogPostSummary_post on File {
    name
    childMarkdownRemark {
      frontmatter {
        title
        summary
        date
        category
        author {
          ...AuthorPanel_author
        }
        image {
          childImageSharp {
            fluid(maxWidth: 910, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
