import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import dayjs from "dayjs"
import { graphql } from "gatsby"

export default function AuthorPanel({ author: { frontmatter }, date }) {
  return (
    <div className="flex flex-ac">
      <Img
        className="author-img"
        fixed={frontmatter.image.childImageSharp.fixed}
        alt={frontmatter.name}
      />
      <div className="flex flex-col">
        <span className="author">{frontmatter.name}</span>
        <span className="post-date">{dayjs(date).format("DD MMMM YYYY")}</span>
      </div>
    </div>
  )
}

AuthorPanel.propTypes = {
  date: PropTypes.string.isRequired,
  author: PropTypes.shape({
    frontmatter: PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  fragment AuthorPanel_author on MarkdownRemark {
    frontmatter {
      name
      image {
        childImageSharp {
          fixed(width: 40, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
