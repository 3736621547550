import React, { Fragment, useMemo, useReducer } from "react"
import Layout from "../components/layout"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import MlIcon from "../components/mlIcon"
import BlogPostSummary from "../components/blogPostSummary"
import AuthorPanel from "../components/authorPanel"
import chunk from "lodash/chunk"

export default () => {
  const {
    page: {
      childMarkdownRemark: { frontmatter },
    },
    posts: { nodes: posts },
  } = useStaticQuery(graphql`
    {
      page: file(relativePath: { eq: "pages/blog.md" }) {
        childMarkdownRemark {
          frontmatter {
            title
            meta {
              title
              description
            }
          }
        }
      }
      posts: allFile(
        filter: { relativeDirectory: { eq: "blog-posts" } }
        sort: {
          fields: [childMarkdownRemark___frontmatter___date]
          order: DESC
        }
        limit: 1000
      ) {
        nodes {
          name
          childMarkdownRemark {
            frontmatter {
              featureImage: image {
                childImageSharp {
                  fluid(maxWidth: 910, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ...BlogPostSummary_post
        }
      }
    }
  `)
  const [numVisiblePages, incrementVisiblePages] = useReducer(
    numVisible => numVisible + 1,
    1
  )
  const featurePost = posts[0]
  const pageSize = 6
  const pages = useMemo(() => chunk(posts.slice(1), pageSize), [posts])
  const visiblePages = useMemo(() => pages.slice(0, numVisiblePages), [
    pages,
    numVisiblePages,
  ])
  const loadMoreVisible = numVisiblePages < pages.length

  return (
    <Layout meta={frontmatter.meta}>
      <section>
        <div className="container">
          <div className="row">
            <div className="sub-page-heading">
              <MlIcon />
              <h1>{frontmatter.title}</h1>
              <h2>Life coaching for optimal performance</h2>
            </div>
          </div>
        </div>
      </section>

      {featurePost && (
        <section className="container">
          <article className="row">
            <Link
              to={`/blog/${featurePost.name}/`}
              className="featured-post grid-featured-post"
            >
              <div className="post-card-img">
                <Img
                  className="img-responsive"
                  fluid={
                    featurePost.childMarkdownRemark.frontmatter.featureImage
                      .childImageSharp.fluid
                  }
                  alt="still mind of a woman with a warm sunset on her mind"
                />
              </div>
              <div className="featured-post-text flex flex-ac">
                <div>
                  <span className="tag">
                    {featurePost.childMarkdownRemark.frontmatter.category}
                  </span>
                  <h3 className="blog-feature-title">
                    {featurePost.childMarkdownRemark.frontmatter.title}
                  </h3>
                  <p className="excerpt">
                    {featurePost.childMarkdownRemark.frontmatter.summary}
                  </p>
                  <AuthorPanel
                    author={featurePost.childMarkdownRemark.frontmatter.author}
                    date={featurePost.childMarkdownRemark.frontmatter.date}
                  />
                </div>
              </div>
            </Link>
          </article>
        </section>
      )}

      <section className="container">
        <div className="grid-3-col">
          {visiblePages.map((page, i) => (
            <Fragment key={i}>
              {page.map(post => (
                <BlogPostSummary key={post.name} post={post} />
              ))}
            </Fragment>
          ))}
        </div>
        {loadMoreVisible && (
          <div className="flex flex-jc mt-2">
            <button className="btn btn-green" onClick={incrementVisiblePages}>
              Load more
            </button>
          </div>
        )}
      </section>
    </Layout>
  )
}
