import React from "react"

const MlIcon = () => (
  <div className="mb-05">
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 86 86"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-724.000000, -493.000000)"
          fill="#01bdbe"
          fillRule="nonzero"
        >
          <g
            id="Machine-Learning"
            transform="translate(724.000000, 493.000000)"
          >
            <path
              d="M43,0.282 C41.44,0.282 39.973,0.889 38.87,1.993 C37.767,3.097 37.159,4.563 37.159,6.124 C37.159,7.684 37.766,9.151 38.87,10.254 C39.974,11.357 41.44,11.965 43,11.965 C44.56,11.965 46.028,11.357 47.131,10.254 C48.235,9.15 48.842,7.684 48.842,6.124 C48.842,4.563 48.235,3.097 47.131,1.993 C46.027,0.89 44.56,0.282 43,0.282 Z"
              id="Path"
              className="svg-elem-1"
            ></path>
            <path
              d="M57.309,65.568 C58.413,66.672 59.879,67.28 61.44,67.28 C63.001,67.28 64.467,66.673 65.57,65.569 C66.674,64.465 67.281,62.999 67.281,61.438 C67.281,59.877 66.674,58.411 65.57,57.307 C64.466,56.203 62.999,55.596 61.439,55.596 C59.878,55.596 58.412,56.203 57.308,57.307 C56.204,58.411 55.597,59.876 55.597,61.437 C55.597,62.998 56.205,64.465 57.309,65.568 Z"
              id="Path"
              className="svg-elem-2"
            ></path>
            <path
              d="M28.692,20.431 C27.588,19.328 26.122,18.72 24.562,18.72 C23.001,18.72 21.534,19.328 20.431,20.431 C19.328,21.534 18.721,23.001 18.721,24.561 C18.721,26.121 19.329,27.588 20.432,28.691 C21.536,29.795 23.003,30.402 24.563,30.402 C26.123,30.402 27.589,29.795 28.692,28.692 C29.796,27.588 30.403,26.121 30.403,24.561 C30.403,23.001 29.796,21.534 28.692,20.431 Z"
              id="Path"
              className="svg-elem-3"
            ></path>
            <path
              d="M37.455,37.456 C37.694,37.217 37.949,37.002 38.212,36.799 L30.763,29.35 C30.559,29.613 30.345,29.868 30.106,30.107 C29.867,30.346 29.612,30.561 29.349,30.764 L36.798,38.213 C37.001,37.95 37.216,37.694 37.455,37.456 Z"
              id="Path"
              className="svg-elem-4"
            ></path>
            <path
              d="M6.124,37.158 C4.563,37.158 3.096,37.765 1.993,38.869 C0.89,39.973 0.283,41.439 0.283,43 C0.283,44.561 0.89,46.028 1.993,47.13 C3.097,48.234 4.564,48.842 6.125,48.842 C7.685,48.842 9.151,48.234 10.255,47.131 C12.532,44.854 12.533,41.147 10.255,38.868 C9.152,37.765 7.685,37.158 6.124,37.158 Z"
              id="Path"
              className="svg-elem-5"
            ></path>
            <path
              d="M19.017,30.105 C18.778,29.866 18.563,29.611 18.36,29.348 L10.912,36.796 C11.175,36.999 11.43,37.214 11.669,37.453 C11.909,37.693 12.123,37.949 12.326,38.21 L19.774,30.762 C19.511,30.559 19.256,30.344 19.017,30.105 Z"
              id="Path"
              className="svg-elem-6"
            ></path>
            <path
              d="M55.894,30.105 C55.655,29.866 55.44,29.611 55.237,29.348 L47.788,36.796 C48.051,37 48.306,37.214 48.545,37.453 C48.784,37.692 48.998,37.947 49.202,38.21 L56.651,30.762 C56.388,30.559 56.132,30.344 55.894,30.105 Z"
              id="Path"
              className="svg-elem-7"
            ></path>
            <path
              d="M74.331,37.455 C74.57,37.216 74.825,37.001 75.088,36.798 L67.64,29.35 C67.436,29.613 67.222,29.868 66.983,30.107 C66.744,30.346 66.489,30.561 66.226,30.764 L73.675,38.213 C73.877,37.95 74.091,37.695 74.331,37.455 Z"
              id="Path"
              className="svg-elem-8"
            ></path>
            <path
              d="M84.008,38.87 C82.904,37.766 81.437,37.158 79.876,37.158 C78.315,37.158 76.849,37.766 75.745,38.869 C73.468,41.147 73.469,44.854 75.746,47.131 C76.85,48.235 78.316,48.842 79.877,48.842 C81.438,48.842 82.904,48.235 84.008,47.132 C86.285,44.854 86.285,41.147 84.008,38.87 Z"
              id="Path"
              className="svg-elem-9"
            ></path>
            <path
              d="M65.568,20.43 C64.464,19.327 62.999,18.72 61.438,18.72 C59.877,18.72 58.411,19.328 57.307,20.431 C56.203,21.534 55.596,23.001 55.596,24.561 C55.596,26.121 56.203,27.588 57.307,28.691 C58.411,29.794 59.877,30.402 61.438,30.402 C62.999,30.402 64.465,29.794 65.568,28.691 C66.672,27.587 67.279,26.121 67.279,24.561 C67.279,23.001 66.672,21.534 65.568,20.43 Z"
              id="Path"
              className="svg-elem-10"
            ></path>
            <path
              d="M43,37.158 C41.44,37.158 39.973,37.766 38.869,38.87 C37.766,39.973 37.158,41.44 37.158,43 C37.158,44.56 37.765,46.027 38.869,47.13 C39.973,48.233 41.439,48.841 43,48.841 C44.56,48.841 46.026,48.234 47.13,47.131 C48.234,46.027 48.842,44.561 48.842,43 C48.842,41.439 48.234,39.972 47.13,38.868 C46.026,37.765 44.561,37.158 43,37.158 Z"
              id="Path"
              className="svg-elem-11"
            ></path>
            <path
              d="M24.563,55.596 C23.002,55.596 21.536,56.204 20.432,57.308 C19.328,58.412 18.721,59.878 18.721,61.439 C18.721,62.999 19.329,64.465 20.432,65.569 C21.536,66.673 23.003,67.28 24.563,67.28 C26.123,67.28 27.59,66.673 28.693,65.569 C29.796,64.465 30.404,62.999 30.404,61.438 C30.404,59.877 29.797,58.411 28.693,57.307 C27.589,56.204 26.123,55.596 24.563,55.596 Z"
              id="Path"
              className="svg-elem-12"
            ></path>
            <path
              d="M43,74.035 C41.439,74.035 39.973,74.642 38.869,75.745 C36.591,78.022 36.592,81.729 38.869,84.008 C39.973,85.111 41.44,85.719 43,85.719 C44.56,85.719 46.027,85.111 47.131,84.008 C49.409,81.73 49.409,78.024 47.131,75.745 C46.028,74.643 44.561,74.035 43,74.035 Z"
              id="Path"
              className="svg-elem-13"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
)

export default MlIcon
